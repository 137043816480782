import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';



import { Utility } from '../_helpers/utility';
import { SalesByLocations, LiveTransaction, SalesAnalytics, SalesSummary, SalesTodaySummary, CustomerTodaySummary, OrderTodaySummary } from '../_models/Dashboard';
import { DashboardReport } from '../_models/dashboard-report.model';
import { SortColumn } from '../_directives/sortable.directive';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) {
  }
  compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  public sort(data: SalesByLocations[], column: SortColumn, direction: string): SalesByLocations[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = this.compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}
  getAnalytics(brandId, locationId, period) {
    const toDate = Utility.setDateFormatforAPI(period.toDate);
    const fromDate = Utility.setDateFormatforAPI(period.fromDate);
    return this.http.get<SalesAnalytics[]>(`api/dashboard/analytics/${brandId}/${locationId}/${fromDate}`);
  }
  getSalesSummary(brandId, locationId, period) {
    const fromDate = Utility.setDateFormatforAPI(period.fromDate);
    const toDate = Utility.setDateFormatforAPI(period.toDate);
    return this.http.get<SalesSummary[]>(`api/dashboard/summary/${brandId}/${locationId}/${fromDate}`);
  }

  getTodaySummary(brandId, locationId, period) {
    const fromDate = Utility.setDateFormatforAPI(period.fromDate);
    const toDate = Utility.setDateFormatforAPI(period.toDate);
    return this.http.get<SalesTodaySummary[]>(`api/dashboard/period/${brandId}/${locationId}/${fromDate}/${toDate}`);
  }

  getLocationSales(brandId, period) {
    const fromDate = Utility.setDateFormatforAPI(period.fromDate);
    const toDate = Utility.setDateFormatforAPI(period.toDate);
    return this.http.get<SalesByLocations[]>(`api/dashboard/salesbylocations/${brandId}/${fromDate}`);
  }

  getTransactions(brandId, locationId, busniessDate) {
    const date = Utility.setDateFormatforAPI(busniessDate);
    const syncDate = Utility.setDateFormatforAPI(busniessDate);
    return this.http.get<LiveTransaction[]>(`api/dashboard/live/${brandId}/${locationId}/${date}/${syncDate}`);
  }

  getDashboardReport(brandId, locationId, period) {
    const fromDate = Utility.setDateFormatforAPI(period.fromDate);
    const toDate = Utility.setDateFormatforAPI(period.toDate);
    return this.http.get<DashboardReport>(`api/Dashboard/report/${brandId}/${locationId}/${fromDate}/${toDate}`);
  }

  getTodayCustomerSummary(brandId, locationId, period) {
    const fromDate = Utility.setDateFormatforAPI(period.fromDate);
    const toDate = Utility.setDateFormatforAPI(period.toDate);
    return this.http.get<CustomerTodaySummary[]>(`api/dashboard/Customer/period/${brandId}/${locationId}/${fromDate}/${toDate}`);
  }
  getTodayOrderSummary(brandId, locationId, period) {
    const fromDate = Utility.setDateFormatforAPI(period.fromDate);
    const toDate = Utility.setDateFormatforAPI(period.toDate);
    return this.http.get<OrderTodaySummary[]>(`api/dashboard/Order/period/${brandId}/${locationId}/${fromDate}/${toDate}`);
  }

}
