import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { SalesDetail } from '../_models/GeneratedReport';

import * as $ from 'jquery';


interface SalesDetailReportResult {
  data: SalesDetail[];
  total: number;
}
interface SalesDetailsReportHeaderState {
  locations: string[]
}
export class SalesDetailsTotals {
  Price: number = 0;
  Cost: number = 0;
  Discount: number = 0;
  Refund: number = 0;
  TotalTaxes: number = 0;
  Vat: number = 0;
  GrandTotal: number = 0;
  Profit: number = 0;
  PaidAmount: number = 0;
}
function matcheHeader(data: SalesDetail, filterValues: SalesDetailsReportHeaderState) {
  let location = false;

  // Order Taker
  if (filterValues.locations !== null && filterValues.locations.length > 0) {
    for (var i = 0, len = filterValues.locations.length; i < len; i++) {
      if (data.Location.toLowerCase().toString().includes(filterValues.locations[i].toLowerCase())) {
        location = true;
      }
    }
  }
  else {
    location = true;
  }

  if (location) {
    return data;
  }
}
export const contentHeaders = new Headers();
const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: SalesDetail[], column: SortColumn, direction: string): SalesDetail[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = `${a[column]}`.toString().localeCompare(`${b[column]}`.toString(), undefined, { numeric: true });
      //const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: SalesDetail, term: string) {
  return data.OfflineUniqueID.toLowerCase().includes(term.toLowerCase()) ||
    data.DeviceTransactionNo.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.Location.toLowerCase().includes(term.toLowerCase()) ||
    data.CheckoutBy.toLowerCase().includes(term.toLowerCase()) ||
    data.PaymentMode.toLowerCase().includes(term.toLowerCase()) ||
    data.OrderType.toLowerCase().includes(term.toLowerCase()) ||
    data.OrderNo.toString().toLowerCase().includes(term.toLowerCase()) ||
    data.OrderRefrenceID.toString().toLowerCase().includes(term.toLowerCase())
}

@Injectable({
  providedIn: 'root'
})
export class SalesDetailReportService {


  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<SalesDetail[]>([]);
  private _data$ = new BehaviorSubject<SalesDetail[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _dynamicTaxColumns: string[] = [];
  private _tableAvailable: boolean = null;
  private _searchHeader$ = new Subject<void>();
  salesDetailsTotals: SalesDetailsTotals;
  public report: any;
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  private _headerState: SalesDetailsReportHeaderState = {
    locations: null
  }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get taxColumns() { return this._dynamicTaxColumns; }
  get tableAvailable() { return this._tableAvailable; }
  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }
  get headerFilter() { return this._headerState; }
  set headerFilter(headerFilter: SalesDetailsReportHeaderState) { this._state.page = 1; this._setHeader(headerFilter); }
  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }
  constructor(private http: HttpClient) {
  }

  public getSalesDetailReport(dataUrl) {


    let _this = this;
    $.ajax({
      method: 'GET',
      url: dataUrl,
      crossDomain: true,
      // cache: false,
      success: function (res) {
        if (res) {
          _this.report = res;
          if (!(res[0].ReportVersion || res[0].ReportVersion === 'V2')) {
            _this.report.forEach(e => {
              e.Profit = e.GrandTotal - e.Cost - e.TaxAmount;
              e.GrandTotal = e.GrandTotal - e.RefundAmount;
              e.DiscountPercentage = ((e.DiscountAmount / (e.GrandTotal - e.TaxAmount + e.DiscountAmount)) * 100);
              e.Details.forEach(ed => {
                ed.PriceWithVAT = parseFloat((ed.PriceWithVAT * ((100 - e.DiscountPercentage) / 100)).toFixed(2));
              });
            });
          }
          _this._tableAvailable = _this.report.some(a => (a.TableName));

          _this._data$.next(_this.report);
          _this._allData$.next(_this.report);

          //new Set(_this.report.map(val => val.Taxes)).forEach(e => {
          //  _this._dynamicTaxColumns.push(e.Name)
          //});
          
          _this._search$.pipe(
            switchMap(() => _this._searchByHeader()),
            tap(() => _this._loading$.next(false))
          ).subscribe(result => {
            _this._data$.next(result.data);
            //_this._allData$.next(result.data);
            _this._total$.next(result.total);
            _this.salesDetailsTotals = _this.getSalesDetailsTotals(result.data);
          });

          _this._searchHeader$.pipe(
            switchMap(() => _this._searchByHeader()),
            tap(() => _this._loading$.next(false))
          ).subscribe(result => {
            _this._data$.next(result.data);
            _this._total$.next(result.total);
          });

          _this._search$.next();
          _this._searchHeader$.next();

        } else {

        }
      }
      , error: function (data: any) {
        console.log(data);
      }
    })

  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }
  private _setHeader(headerFilter: SalesDetailsReportHeaderState) {
    this._headerState = headerFilter;
    this._searchHeader$.next();
  }

  private _search(): Observable<SalesDetailReportResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  ColumnList =
    [
      {
        name: "TRANSACTION", value: "DeviceTransactionNo", isChecked: true
      },
      {
        name: "Partner", value: "Partner", isChecked: true
      },
      {
        name: "Order No", value: "OrderNo", isChecked: true
      },
      {
        name: "Order Reference", value: "OrderRefrenceID", isChecked: true
      },
      {
        name: "Price", value: "TotalAmount", isChecked: true
      },
      {
        name: "Cost", value: "Cost", isChecked: true
      },
      //{
      //  name: "Discount Percentage", value: "DiscountPercentage", isChecked: true
      //},
      {
        name: "Discount", value: "DiscountAmount", isChecked: true
      },
      {
        name: "Refund", value: "RefundAmount", isChecked: true
      },
      {
        name: "Tax", value: "TaxAmount", isChecked: true
      },
      {
        name: "Grand Total", value: "GrandTotal", isChecked: true
      },
      {
        name: "MultiPayments", value: "MultiPayments", isChecked: true
      },
      {
        name: "Profit", value: "Profit", isChecked: true
      },
      {
        name: "Location", value: "Location", isChecked: true
      },
      {
        name: "Time", value: "CheckoutDate", isChecked: true
      },
      {
        name: "User", value: "CheckoutBy", isChecked: true
      },
      {
        name: "Pay Type", value: "PaymentMode", isChecked: true
      },
      {
        name: "Order Type", value: "OrderType", isChecked: true
      }

    ]


  private _searchByHeader(): Observable<SalesDetailReportResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matcheHeader(data, this._headerState));
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }
  getFilteredData() {
    if (this.headerFilter?.locations?.length > 0) {
      return this.allData$.pipe(
        map(data => data.filter(item => this.headerFilter.locations.includes(item.Location)))
      );
    }
    return this.allData$;
  }
  getSalesDetailsTotals(salesDetails: SalesDetail[]): SalesDetailsTotals {
    const totals = new SalesDetailsTotals();
    //let vat = salesDetails.reduce((acc, x) => acc + x.Taxes.find(e => e.Name === "VAT")?.Amount || 0, 0);
    salesDetails.forEach(sale => {
      totals.Discount += sale.DiscountAmount;
      totals.Cost += sale.Cost;
      totals.GrandTotal += sale.GrandTotal;
      totals.PaidAmount += sale.MultiPayments;
      totals.Price += sale.TotalAmount;
      totals.Profit += sale.Profit;
      totals.Refund += sale.RefundAmount;
      totals.TotalTaxes += sale.TaxAmount;
      totals.Vat += sale.Taxes.find(e => e.Name === "VAT")?.Amount || 0;
    });

    return totals;
  }
  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._data$ = new BehaviorSubject<SalesDetail[]>([]);
    this._allData$ = new BehaviorSubject<SalesDetail[]>([]);
    this._total$.next(null);
    this._loading$.next(null);
    this._dynamicTaxColumns = [];
    this._headerState = {
      locations: null
    };
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }
}
