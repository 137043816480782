import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { Dayjs} from 'dayjs';

@Component({
  selector: 'app-date-range-picker-v2',
  templateUrl: './date-range-picker-v2.component.html',
  styleUrl: './date-range-picker-v2.component.css'
})
export class DateRangePickerV2Component {
  selected;
  fromDate;
  toDate;

  alwaysShowCalendars: boolean;
  ranges: any = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'This Week': [moment().startOf('week'), moment().endOf('week')],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
    'Last quarter': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
    'This Year': [moment().startOf('year'), moment().endOf('year')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  maxDate = moment().toISOString();
  minDate = moment().subtract(12, 'month').toISOString();
  isInvalidDate = (m: moment.Moment) => {
    return this.invalidDates.some(d => d.isSame(m, 'day'))
  }

  @Output() OnHit: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.alwaysShowCalendars = true;
    this.selected = { startDate: moment(), endDate: moment() };
    
  }
  openDatepicker() {
    this.pickerDirective.open();
  }
  ngOnInit() {
  }

  datesUpdated(e) {
    if (this.selected.startDate.$d) {
      this.fromDate = this.selected.startDate.$d;
      var d = new Date(this.selected.endDate.$d.toString());
      d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
      this.toDate = d;
    }
    else {
      this.fromDate = this.selected.startDate._d;
      this.toDate = this.selected.endDate._d;
    }
    this.OnHit.emit('Click');
  }
}
