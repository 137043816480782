

import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { Refunds, InventoryLog } from 'src/app/_models/GeneratedReport';

import { Router } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import { ExcelService } from '../../../_services/excel.service';
import { Location } from '@angular/common';
import { RefundReportService } from '../../../_services/refund.report.service';
import { defaultIfEmpty, filter, map } from 'rxjs/operators';
import { SortColumn } from '../../../_directives/sortable.directive';
import { DataOperationsService } from '../../../_services/data-operations.service';


@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit {
  data$: Observable<Refunds[]>;
  allData$: Observable<Refunds[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  submit: boolean;
  selectedReport: any;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  sortColumn: SortColumn;
  sortDirection: string;
  constructor(
    public service: RefundReportService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private printService: PrintService,
    private alertService: AlertService, private excelService: ExcelService, private dataOperationsService: DataOperationsService) {


    this.loading$ = service.loading$;
    this.submit = false;


    this.selectedReport = history.state;
    //this.selectedReport = { "generatedReportID": 270, "reportID": 1, "reportName": "Refund", "dateRange": "2021-06-01 - 2021-07-31", "locations": "Al-Rayan,Al Salam,Test Location,Test Create,Test-MARN-1,test-22,test-2,raf,test3,testlocation,cafeimran,cafeimran,test12,test12,Ahsan's Location,Ahsan's Location,Location 44,Location 44,Location 44,test1,Malir,Karachi,Gulshan e iqbal,MarnNow,MarnNow,MarnNow,AL-haris,Test Location,Muhammad Shahzad,Muhammad Shahzad,Test 2,loc 32,loc 43,loc 44,loc 45,loc 46,test loc n48,new 49,test 50,test 50,nescafe,nescafeee", "generatedBy": "MARN POS", "generatedOn": "2021-08-02T23:10:41", "filters": "locations:1,69,70,71,89,90,91,92,93,96,97,98,100,101,102,103,104,105,106,109,113,114,115,116,117,118,120,123,124,125,126,143,144,145,146,147,152,154,155,157,158,159| users:null| reference:null", "dataURL": "https://marnpossastorage.blob.core.windows.net/marn-reports-v2/2021-08-02/log-20210802T201041654", "brandID": 1, "rowVersion": 20210802201041, "statusID": 1 };

    console.log(this.selectedReport);
    //this.getReportData(this.selectedReport.dataURL);



  }

  Refunds: any;
  ngOnInit() {
    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
    }, 1000);

   
  }



  getReportData(dataURL) {
    this.service.getRefundReport(dataURL);

    this.data$ = this.service.data$;
    this.allData$ = this.service.allData$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;

    //this.printService.onDataReady();
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

  }

  export(type, data): void {



    if (data !== undefined && data !== null && data !== "") {
      this.excelService.exportAsExcelFile(this.getExportData(data), 'Export-refund', type);
    }
    else
      (data !== undefined) ? (data.length === 0 ? this.alertService.error("Data is not available.") : "") : "";
  }


  private getExportData(data: { source: { _value: Refunds[] } }): Partial<Refunds>[] {
    let sortedData: any;
    const columns = {
      V2: ['ItemName', 'OrderNo', 'Location', 'OrderTaker', 'OrderType', 'Reason', 'RefundAmount', 'BusinessDate'],
      V1: ['OrderNo', 'Location', 'OrderTaker', 'Reason', 'Type', 'TotalAmount', 'RefundAmount', 'BusinessDate']
    };
    
    const neededColumns = columns[this.service.hasReportVersionV2 ? 'V2' : 'V1'];
    const allData = this.service.report;      ;

    // Determine the data to filter (selected or all if none selected)
    const dataToExport = allData.some(d => d.selected) ? allData.filter(d => d.selected) : allData;

    sortedData = this.dataOperationsService.prepareExportData(dataToExport, this.sortColumn, this.sortDirection)

    const filteredColumnData = sortedData.map(item =>
      neededColumns.reduce((acc, key) => {
        if (item[key] !== undefined) acc[key] = item[key];
        return acc;
      }, {} as Partial<Refunds>)
    );

    if (this.service.hasReportVersionV2 && this.service.totalRefundedRows) {
      const totalRow: Partial<Refunds> = {};

      neededColumns.forEach((column) => {
        totalRow[column] = ''; // Initialize with empty string or default value
      });

      const totalRowsText = `Total of ${dataToExport.length} rows`;
      totalRow['ItemName'] = totalRowsText;

      const totalRefundAmount = parseFloat(dataToExport.reduce((sum, refund) => sum + refund.RefundAmount, 0).toFixed(2));

      // Format the number as a string with two decimal places
      totalRow['RefundAmount'] = totalRefundAmount;

      filteredColumnData.push(totalRow);
    }

    return sortedData;
  }


  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

}
