

import { Component, HostListener } from '@angular/core';
import { UserinfoService } from '../_services/userinfo.service';

import { LocalStorageService } from '../_services/local-storage.service';
import { AuthenticationService } from '../_services/authentication.service';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReceiptComponent } from '../setup/receipt/receipt.component';

import $ from "jquery";
import { CreatebrandComponent } from '../brand/createbrand/createbrand.component';
import * as myGlobals from '../_helpers/globals';
import { UpdateProfileComponent } from '../update-profile/update-profile.component';
import { AccessRights } from '../_models/access-rights';
import { release } from 'os';
import { ActionConfirmationService } from '../_services/action-confirmation.service';
import { AlertService } from '../_services/alert.service';
import { NgbdBrandCreationModalConfirm } from './brand-creation-confirmation';
import { BrandService } from '../_services/brand.service';
import { BrandCreationConfirmationComponent } from './brand-creation-confirmation/brand-creation-confirmation.component';
import { ChatBotService } from '../_services/chat-bot.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { TrackEventsService } from '../_services/track-events.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {



  toggled: boolean = false;
  Brands: any;
  Locations: any;
  ImageError: boolean;
  SelectedBrand: any;
  SelectedLocation: any;
  SelectedUser: any;
  screenWidth: number;
  userName: string = '';
  rights: AccessRights[];
  applyDynamicMenu: boolean = false;
  industryURLName = '';
  constructor(private userInfoService: UserinfoService,
    private _brandService: BrandService,
    private _modalService: NgbModal,
    private confirmationDialogService: ActionConfirmationService, private alertService: AlertService,
    private localStorageService: LocalStorageService,
    private authenticationService: AuthenticationService,
    public chatbotService: ChatBotService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private trackEvents: TrackEventsService,
    private modalService: NgbModal) {
    this.getUserInfo();
    this.getAccessRights();
    this.ImageError = false;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 767) {
      this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationEnd) {
          console.log('call');
          $(".page-wrapper").removeClass("toggled");
          // $(".page-wrapper .sidebar-wrapper").attr("style", 'left: -100%');

        }
      });
    }
    this.applyDynamicMenu = this.localStorageService.getSelectedUser().CompanyDetails.Subscribe;



  }


  ngAfterViewInit() {
    $(".sidebar-dropdown > a").click(function () {
      $(".sidebar-submenu").slideUp(200);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .next(".sidebar-submenu")
          .slideDown(200);
        $(this)
          .parent()
          .addClass("active");
      }
    });


    $("#close-sidebar").click(function () {
      $(".page-wrapper").removeClass("toggled");
      $(".page-wrapper .sidebar-wrapper").attr("style", 'left: -100%');
      $("#close-sidebar").attr("style", 'display: none');
    });

    $("#show-sidebar").click(function () {
      $(".page-wrapper").addClass("toggled");
      $(".page-wrapper.toggled .sidebar-wrapper").attr("style", 'left: 0px');
      if (window.innerWidth <= 767)
        $("#close-sidebar").attr("style", 'display:block');
    });
  }
  ngOnInit() {
    //this.getUserInfo();
    this.onResize();
    this.getUserName();
    this.industryURLName= myGlobals.industryName(this.localStorageService);

  }
  private getUserInfo() {
    this.userInfoService.getUserInfo().then((res: any) => {

      this.Brands = res.Brands;
      console.log(this.Brands);
      this.SelectedUser = res.User;
      if (this.localStorageService.checkAlreadySelected()) {

        this.SelectedBrand = this.localStorageService.getSelectedBrand();
        console.info('Selected Brand', this.SelectedBrand);
        console.log(this.SelectedBrand.ZATCACompliance);
        if (this.SelectedBrand.ZATCACompliance === undefined) {
          this.SelectedBrand = this.Brands.filter(b => b.BrandID === this.SelectedBrand.BrandID)[0];
          console.info('Update Selected Brand', this.SelectedBrand)
          this.localStorageService.setSelectedBrand(this.SelectedBrand);
        }

        this.Locations = this.SelectedBrand.Locations;
        this.SelectedLocation = this.Locations[0];
        this.SelectedLocation = this.localStorageService.getSelectedLocation();
      }
      else {
        this.SelectedBrand = this.Brands[0];
        this.Locations = this.Brands[0].Locations;
        this.SelectedLocation = this.Locations[0];

        this.localStorageService.setSelectedBrand(this.SelectedBrand);
        this.localStorageService.setSelectedLocation(this.SelectedLocation);
      }
      this.userName = this.SelectedUser.FirstName;
      console.info('header data',
        this.SelectedBrand,
        this.SelectedLocation,
        this.SelectedUser
      )



      //SET INTERCOM VALUES
      this.chatbotService.loadInterCom(
        this.SelectedUser.FirstName,
        this.SelectedUser.Email,
        this.SelectedUser.Contact,
        this.SelectedBrand.BrandName,
        this.SelectedLocation.Name,
        this.Locations.filter(l => l.LocationID !== null).length,
        this.SelectedUser.Industry === 1 ? 'Restaurant' : 'Retail',
        this.SelectedUser.Type,
        this.localStorageService.getSelectedUser().CompanyDetails.CompanyCode,
        window.location.href,
        this.SelectedBrand.BrandID + "" + this.SelectedUser.UserID);
        this.trackEvents.handleUserData();
    });
  }

  getUserName() {
    if (this.localStorageService.getSelectedUser() != null && this.localStorageService.getSelectedUser() != undefined) {
      let user = this.localStorageService.getSelectedUser().User;
      this.userName = user.FirstName;
    }
  }

  public errorHandler(event) {
    this.ImageError = true;
  }

  public changeBrand(brand) {
    this.SelectedBrand = brand;
    this.SelectedLocation = brand.Locations[0];
    this.localStorageService.setSelectedBrand(this.SelectedBrand);
    this.localStorageService.setSelectedLocation(this.SelectedLocation);
    window.location.reload();
  }

  public changeLocation(location) {

    this.SelectedLocation = location;
    this.localStorageService.setSelectedLocation(this.SelectedLocation);
    window.location.reload();
  }

  public toggleMobileMenu() {
    this.toggled = !this.toggled;
  }

  public logout() {
    this.authenticationService.logout();
    this.trackEvents.Logout();
    this.router.navigate(['/']);
  }
  openReceipt() {
    const modalRef = this.modalService.open(ReceiptComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("View Receipt");
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
  openBrand() {
    const modalRef = this.modalService.open(CreatebrandComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("View Brand");
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
  openProfile() {
    const modalRef = this.modalService.open(UpdateProfileComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("View Brand");
    modalRef.componentInstance.SelectedID = this.SelectedUser.UserID;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
  editBrand(brand) {
    const modalRef = this.modalService.open(CreatebrandComponent, { size: 'xl', windowClass: 'full-modal', animation: false });
    this.trackEvents.Log_Screen("Edit Brand");
    modalRef.componentInstance.SelectedID = brand.BrandID;
    modalRef.componentInstance.SelectedItemName = brand.BrandName;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }
  changeOfRoutes() {
    console.log('changes route');
    this.trackEvents.handleRouteEvents(this.router);
    if (this.router.url === '/') {

    }
  }


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= 767) {
      $(".page-wrapper").removeClass("toggled");
      $(".page-wrapper .sidebar-wrapper").attr("style", 'left: -100%');
      $(".page-wrapper.toggled .sidebar-wrapper").removeAttr("style");
      this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationEnd) {
          console.log('call resize');
          $(".page-wrapper").removeClass("toggled");
          $(".page-wrapper .sidebar-wrapper").attr("style", 'left: -450px');
          $(".page-wrapper.toggled .sidebar-wrapper").removeAttr("style");

        }
      });

    }
    else {
      $(".page-wrapper").addClass("toggled");
      $(".page-wrapper.toggled .sidebar-wrapper").attr("style", 'left: 0px');
    }

  }

  dismissLocation() {
    this.SelectedLocation.LocationID = null;
    this.SelectedLocation.Name = 'All Locations';

    this.localStorageService.setSelectedLocation(this.SelectedLocation);
    window.location.reload();

  }
  getAccessRights() {
    this.rights = this.localStorageService.getSelectedUser().Rights.filter(r => r.IsMenuItem === true).sort((n1, n2) => {
      if (n1.FormOrder > n2.FormOrder) {
        return 1;
      }

      if (n1.FormOrder < n2.FormOrder) {
        return -1;
      }

      return 0;
    });
  }
  verifySubmenu(menu) {
    if (menu.Parent === true) {
      var count = menu.ChildRights.reduce((counter, obj) => (obj.FormType.toLowerCase() !== 'right' && obj.IsMenuItem === true) ? counter += 1 : counter, 0);
      return count;
    }
    else {
      return 0;
    }
  }


  openEmailConformation() {
    const modalRef = this._modalService.open(BrandCreationConfirmationComponent, { centered: true });
    this.trackEvents.Log_Screen("Create Brand");
    modalRef.result.then((result) => {
    }, (confirmed) => {
      if (confirmed) {

        this._brandService.createRequestNewBrand().subscribe(res => {
          this.alertService.success("New brand creation request has been send.");
        }, error => {
          this.alertService.error("Request faild please check again");
        })
      }
    });
  }
}
