
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { Discounts } from 'src/app/_models/GeneratedReport';

import { Router } from '@angular/router';
import { PrintService } from 'src/app/_services/print.service';
import { ExcelService } from '../../../_services/excel.service';
import { Location } from '@angular/common';
import { DiscountReportService } from '../../../_services/discount.report.service';
import { DiscountsWithTotal } from '../../../_models/GeneratedReport';
import { DataOperationsService } from '../../../_services/data-operations.service';
import { SortColumn } from '../../../_directives/sortable.directive';



@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.css']
})


export class DiscountComponent implements OnInit {
  data$: Observable<Discounts[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;

  submit: boolean;
  selectedReport: any;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  sortColumn: SortColumn;
  sortDirection: string;
  constructor(
    public service: DiscountReportService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private printService: PrintService,
    private alertService: AlertService, private excelService: ExcelService, private dataOperationService: DataOperationsService) {


    this.loading$ = service.loading$;
    this.submit = false;


    this.selectedReport = this.router.getCurrentNavigation().extras.state;
    //this.selectedReport = { "generatedReportID": 270, "reportID": 1, "reportName": "Discount", "dateRange": "2021-06-01 - 2021-07-31", "locations": "Al-Rayan,Al Salam,Test Location,Test Create,Test-MARN-1,test-22,test-2,raf,test3,testlocation,cafeimran,cafeimran,test12,test12,Ahsan's Location,Ahsan's Location,Location 44,Location 44,Location 44,test1,Malir,Karachi,Gulshan e iqbal,MarnNow,MarnNow,MarnNow,AL-haris,Test Location,Muhammad Shahzad,Muhammad Shahzad,Test 2,loc 32,loc 43,loc 44,loc 45,loc 46,test loc n48,new 49,test 50,test 50,nescafe,nescafeee", "generatedBy": "MARN POS", "generatedOn": "2021-08-02T23:10:41", "filters": "locations:1,69,70,71,89,90,91,92,93,96,97,98,100,101,102,103,104,105,106,109,113,114,115,116,117,118,120,123,124,125,126,143,144,145,146,147,152,154,155,157,158,159| users:null| reference:null", "dataURL": "https://marnpossastorage.blob.core.windows.net/marn-reports-v2/2021-08-02/log-20210802T201041654", "brandID": 1, "rowVersion": 20210802201041, "statusID": 1 };

    console.log(this.selectedReport);
    //this.getReportData(this.selectedReport.dataURL);




  }


  ngOnInit() {
    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
    }, 1000);
  }



  getReportData(dataURL) {
    this.service.getDiscountReport(dataURL);

    this.data$ = this.service.allData$;

    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;



    //this.printService.onDataReady();
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();

  }

  export(type, data): void {



    if (data !== undefined && data !== null && data !== "") {
      this.excelService.exportAsExcelFile(this.getExportData(data), 'Export-discount', type);
    }
    else
      (data !== undefined) ? (data.length === 0 ? this.alertService.error("Data is not available.") : "") : "";
  }


  private getExportData(data) {
    let selected: DiscountsWithTotal[] = data.source._value.filter(d => d.selected);

    selected = selected.length == 0 ? this.service.report : selected;

    selected = this.dataOperationService.prepareExportData(selected, this.sortColumn, this.sortDirection);

    const totalForSelectedRows = this.service.getDiscountsTotals(selected);

    const totalRow = {
      OrderNo: `Total of ${selected.length} rows`,
      TotalAmount: parseFloat(totalForSelectedRows.OrderTotal.toFixed(2)),
      DiscountAmount: parseFloat(totalForSelectedRows.DiscountAmount.toFixed(2)),
      GrandTotal: parseFloat(totalForSelectedRows.GrandTotal.toFixed(2)),
    };

    selected.push(totalRow as DiscountsWithTotal);
    return selected;
  }

  public bulkSelection($event) {
    this.data$.forEach(i => i.forEach(e => e.selected = $event.target.checked));
  }

}
