import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { DailySales } from 'src/app/_models/GeneratedReport';
import { Router } from '@angular/router';
import { ExcelService } from '../../../_services/excel.service';
import { DailySalesService } from '../../../_services/daily-sales.service';
import { SortColumn } from '../../../_directives/sortable.directive';
import { DataOperationsService } from '../../../_services/data-operations.service';

@Component({
  selector: 'app-daily-sales',
  templateUrl: './daily-sales.component.html',
  styleUrls: ['./daily-sales.component.css']
})
export class DailySalesComponent implements OnInit {
  data$: Observable<DailySales[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;

  submit: boolean;
  selectedReport: any;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  sortColumn: SortColumn;
  sortDirection: string;
  constructor(public service: DailySalesService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private excelService: ExcelService, private dataOperationService: DataOperationsService) {
    this.loading$ = service.loading$;
    this.submit = false;

    this.selectedReport = this.router.getCurrentNavigation().extras.state;
    console.log(this.selectedReport);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
      //this.getReportData("https://marnpossastorage.blob.core.windows.net/marnpos-admin-reports/2021-01-30/stock-20210130T103754521");
    }, 1000);
  }

  getReportData(dataURL) {
    this.service.getSalesDetailReport(dataURL);
    this.data$ = this.service.allData$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

  }
  export(type, data): void {
    this.excelService.exportAsExcelFile(this.getExportData(), 'Export-DailySales', type);
  }
  private getExportData() {
    let selected: any;

    selected = [];
    this.service.report.forEach(d => {
      if (d.selected) {
        selected.push(d);
      }
    });


    selected = selected.length == 0 ? this.service.report : selected;

    selected = this.dataOperationService.prepareExportData(selected, this.sortColumn, this.sortDirection)

    const totalRow: Partial<any> = {};

    const totalRowsText = `Total of ${selected.length} rows`;
    totalRow['BusinessDate'] = totalRowsText;

    const totalForSelectedRows = this.service.getDailySalesTotals(selected);

    totalRow['OrderAverage'] = !isNaN(Number(totalForSelectedRows.OrderAverage))
      ? parseFloat(Number(totalForSelectedRows.OrderAverage).toFixed(2))
      : 0,
    totalRow['Card'] = !isNaN(Number(totalForSelectedRows.Card))
      ? parseFloat(Number(totalForSelectedRows.Card).toFixed(2))
      : 0,
      totalRow['Cash'] = !isNaN(Number(totalForSelectedRows.Cash))
      ? parseFloat(Number(totalForSelectedRows.Cash).toFixed(2))
        : 0,
    totalRow['Aggregator'] = !isNaN(Number(totalForSelectedRows.Aggregator))
      ? parseFloat(Number(totalForSelectedRows.Aggregator).toFixed(2))
        : 0,
      
      totalRow['Discount'] = !isNaN(Number(totalForSelectedRows.Discount))
      ? parseFloat(Number(totalForSelectedRows.Discount).toFixed(2))
        : 0,
      totalRow['Complimentary'] = !isNaN(Number(totalForSelectedRows.Complimentary))
      ? parseFloat(Number(totalForSelectedRows.Complimentary).toFixed(2))
      : 0,
      totalRow['Void'] = !isNaN(Number(totalForSelectedRows.Void))
      ? parseFloat(Number(totalForSelectedRows.Void).toFixed(2))
      : 0,
      totalRow['Sales'] = !isNaN(Number(totalForSelectedRows.Sales))
      ? parseFloat(Number(totalForSelectedRows.Sales).toFixed(2))
      : 0,
      totalRow['Refund'] = !isNaN(Number(totalForSelectedRows.Refund))
      ? parseFloat(Number(totalForSelectedRows.Refund).toFixed(2))
      : 0,
      totalRow['GrandTotal'] = !isNaN(Number(totalForSelectedRows.SalesWithTaxes))
      ? parseFloat(Number(totalForSelectedRows.SalesWithTaxes).toFixed(2))
      : 0,
      totalRow['Tax'] = !isNaN(Number(totalForSelectedRows.Tax))
      ? parseFloat(Number(totalForSelectedRows.Tax).toFixed(2))
      : 0,

    selected.push(totalRow);

    return selected;
  }
  public bulkSelection($event) {
    this.data$.forEach(i => i?.forEach(e => e.selected = $event.target.checked));
  }

}
