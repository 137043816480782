import { Injectable} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { switchMap, tap, map } from 'rxjs/operators';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { HttpClient } from '@angular/common/http';
import { State } from '../_models/State';
import { Discounts } from '../_models/GeneratedReport';

import * as $ from 'jquery';

interface SearchDiscountReportResult {
  data: Discounts[];
  total: number;
}
export class discountTotals {
  DiscountAmount: number = 0;
  OrderTotal: number = 0;
  GrandTotal: number = 0;
}
export const contentHeaders = new Headers();
const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: Discounts[], column: SortColumn, direction: string): Discounts[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = `${a[column]}`.toString().localeCompare(`${b[column]}`.toString(), undefined, { numeric: true });
      //const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: Discounts, term: string) {
  return data.OrderNo.toString().includes(term) || data.OrderTaker.toLowerCase().includes(term.toLowerCase()) || data.Discount.toLowerCase().includes(term.toLowerCase())
}

@Injectable({
  providedIn: 'root'
})
export class DiscountReportService {

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<Discounts[]>([]);
  private _data$ = new BehaviorSubject<Discounts[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  public report: any;
  discountTotals: discountTotals;
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }

  constructor(private http: HttpClient) {
  }

  // async downloadFile(CONNECT_STR: string, fileName: string) {
  //   const containerURL = await new AzureStorage.ContainerURL(
  //     CONNECT_STR,
  //     AzureStorage.StorageURL.newPipeline(
  //       new AzureStorage.AnonymousCredential()
  //     ));
  //   const blockBlobURL = BlockBlobURL.fromContainerURL(containerURL, fileName);
  //   const downloadResponse = await blockBlobURL.download(AzureStorage.Aborter.none, 0);
  //   return downloadResponse.blobBody;
  // }


   downloadProfilePic(dataUrl) {
     

    }


  public getDiscountReport(dataUrl) {

  
      let _this = this;
      $.ajax({
        method: 'GET',
        url:  dataUrl,
        crossDomain: true,
        // cache: false,
        success: function (res) {
          if(res){
            console.log(res);
            _this.report = res;
            //_this.report = [
            //  {
            //    OrderNo: "#2345",
            //    Location: "Khobar Store",
            //    OrderTaker: "Abdullah Saleh",
            //    BusinessDate: "12/12/2021",
            //    Discount: "Expired & damage",
            //    Details: [],
            //    TotalAmount: 3465.20,
            //    GrandTotal: 3465.20,
            //    selected: false
            //  }
              
            //];

          _this._data$.next(_this.report);
          _this._allData$.next(_this.report);
  
  
          _this._search$.pipe(
            switchMap(() => _this._search()),
            tap(() => _this._loading$.next(false))
          ).subscribe(result => {
            _this._data$.next(result.data);
            _this._allData$.next(result.data);
            _this._total$.next(result.total);
            _this.discountTotals = _this.getDiscountsTotals(result.data);
          });
          _this._search$.next();
          }else{
             
          }
        }
        ,error: function (data:any) {
            console.log(data);
        }
    })
  
  }


  

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchDiscountReportResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    //const data = sortedData.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    const data = sortedData;
    return of({ data, total });
  }
   getDiscountsTotals(discounts: any[]): discountTotals {
    const totals = new discountTotals();

    discounts.forEach(discount => {
      totals.DiscountAmount += discount.DiscountAmount;
      totals.GrandTotal += discount.GrandTotal;
      totals.OrderTotal += discount.TotalAmount;
    });

    return totals;
  }
  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._data$.next(null);
    this._allData$.next(null);
    this._total$.next(null);
    this._loading$.next(null);
    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };    
  }

  getAllLocationsddl(id) {
    return  this.http.get(`api/location/all/${id}`); 
   }

   getReportTest(url) {
    return  this.http.get(url); 
   }
 
  

}

