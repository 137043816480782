import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ordertype } from '../_models/ordertype';

import * as $ from 'jquery';
import { SortColumn, SortDirection } from '../_directives/sortable.directive';
import { LocationSalesSummaryAnalyticsDto } from '../_models/LocationSalesSummaryAnalyticsDto';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { State } from '../_models/State';
import { switchMap, tap } from 'rxjs/operators';
import { Utility } from '../_helpers/utility';


interface LocationSalesSummaryAnalyticsResult {
  data: LocationSalesSummaryAnalyticsDto[];
  total: number;
}

export const contentHeaders = new Headers();
const compare = (v1: string, v2: string) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

function sort(data: LocationSalesSummaryAnalyticsDto[], column: SortColumn, direction: string): LocationSalesSummaryAnalyticsDto[] {
  if (direction === '' || column === '') {
    return data;
  } else {
    return [...data].sort((a, b) => {
      const res = `${a[column]}`.toString().localeCompare(`${b[column]}`.toString(), undefined, { numeric: true });
      //const res = compare(`${a[column]}`, `${b[column]}`);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(data: LocationSalesSummaryAnalyticsDto, term: string) {
  return data.location.toLowerCase().includes(term.toLowerCase()) ||
    data.city.toLowerCase().includes(term.toLowerCase())
}

@Injectable({
  providedIn: 'root'
})
export class SalesSummaryReportService {

  constructor(private http: HttpClient) { }

  getSalesSummary(brandId, locationId, fromDate, toDate) {
    
    //const newFromDate = fromDate.getFullYear() + '-' +
    //  ("0" + (fromDate.getMonth() + 1)).slice(-2) + '-' +
    //  ("0" + fromDate.getDate()).slice(-2);
    //const newToDate = toDate.getFullYear() + '-' +
    //  ("0" + (toDate.getMonth() + 1)).slice(-2) + '-' +
    //  ("0" + toDate.getDate()).slice(-2);

    //locationId =(locationId == null) ? 1 : location;
    //console.log(`api/SalesSummaryReport/all/${brandId}/${locationId}/${fromDate}/${toDate}`);
    return this.http.get<any>(`api/SalesSummaryReport/all/${brandId}/${locationId}/${fromDate}/${toDate}`);
  }


  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _allData$ = new BehaviorSubject<LocationSalesSummaryAnalyticsDto[]>([]);
  private _data$ = new BehaviorSubject<LocationSalesSummaryAnalyticsDto[]>([]);
  private _total$ = new BehaviorSubject<number>(0);
  private _dynamicTaxColumns: string[] = [];
  private _tableAvailable: boolean = null;
  private _searchHeader$ = new Subject<void>();
  public report: any;
  private _state: State = {
    page: 1,
    pageSize: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };


  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }
  get taxColumns() { return this._dynamicTaxColumns; }
  get tableAvailable() { return this._tableAvailable; }
  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: any) { this._state.page = 1; this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }
  get data$() {
    return this._data$.asObservable();
  }

  get allData$() {
    return this._allData$.asObservable();
  }
  public getLocationSalesSummaryAnalytics(brandId, locationId, fromDate, toDate) {
    const fromDateFormatted = Utility.setDateFormatforAPI(fromDate);
    const toDateFormatted = Utility.setDateFormatforAPI(toDate);
    const url = `api/SalesSummaryReport/GetLocationAnalytics/${brandId}/${locationId}/${fromDateFormatted}/${toDateFormatted}`;
    tap(() => this._loading$.next(true)),
      this.http.get<LocationSalesSummaryAnalyticsDto[]>(url).subscribe(res => {
        this.report = res;
        
        this._data$.next(this.report);
        this._allData$.next(this.report);



        // @@@
        this._search$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });
        this._searchHeader$.pipe(
          switchMap(() => this._search()),
          tap(() => this._loading$.next(false))
        ).subscribe(result => {

          this._data$.next(result.data);
          this._total$.next(result.total);
        });

        this._search$.next();
        this._searchHeader$.next();

      });




  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }


  private _search(): Observable<LocationSalesSummaryAnalyticsResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let sortedData = sort(this.report, sortColumn, sortDirection);

    //// 2. filter
    sortedData = sortedData.filter(data => matches(data, searchTerm));
    const total = sortedData.length;

    // 3. paginate
    const data = sortedData;//.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    this._data$.next(data);
    this._total$.next(total);
    return of({ data, total });
  }

  clear() {
    // clear by calling subject.next() without parameters
    this._search$.next();
    this._data$ = new BehaviorSubject<LocationSalesSummaryAnalyticsDto[]>([]);
    this._allData$ = new BehaviorSubject<LocationSalesSummaryAnalyticsDto[]>([]);
    this._total$.next(null);
    this._loading$.next(null);
    this._dynamicTaxColumns = [];

    this._state = {
      page: 1,
      pageSize: 10,
      searchTerm: '',
      sortColumn: '',
      sortDirection: ''
    };
  }


}
