
<input class="form-control dpFromDate datepicker-calendar-icon text-center p-0 mt-1" readonly="readonly" autocomplete="off"
       type="text"
       ngxDaterangepickerMd
       [closeOnAutoApply]="true"
       [autoApply]="true"
       [(ngModel)]="selected"
       [showCustomRangeLabel]="true"
       [alwaysShowCalendars]="true"
       [showRangeLabelOnInput]="true"
       [ranges]="ranges"
       [linkedCalendars]="true"
       [isInvalidDate]="isInvalidDate"
       [showClearButton]="false"
       placeholder="Select please..."
       (datesUpdated)="datesUpdated($event)"
       [opens]="'center'"
       [locale]="{displayFormat: 'DD MMM YYYY'}"
       role="button"
       [minDate]="minDate"
       [maxDate]="maxDate">

<!--<div class="input-group-append btn-calender">
  <button class="btn btn-outline-secondary input-group-text ngx-daterangepicker-action"
          (click)="openDatepicker()" type="button">
    <img src="/assets/images/calendar.svg" alt="Calender">
  </button>
</div>-->
