<main class="views container">
  <div class="page">


    <div class="custom-action-header">
      <div class="row">
        <div class="col-sm-3 col-xl-3 col-lg-12 col-md-12 title-space-mb">
          <div class="breadcrumb">
            <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
          </div>
          <div class="title">
            <span>{{'Sales Order Type' | translate }} </span>
          </div>
        </div>
        <div class="col-sm-9 col-xl-9 col-lg-12 col-md-12">
          <div class="row flex-row-reverse p-2 mt-10 custom-action-header">

            <div class="col-md-auto">
              <input class="form-control search-text " placeholder="{{'Search' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
            </div>
            <div class="col-md-auto">
            </div>


          </div>
        </div>
      </div>
    </div>



    <!--<div class="page-header">
      <div class="breadcrumb">
        <a backLink>{{'Report' | translate }} </a>&nbsp;/ {{'Preview' | translate }}
      </div>
      <div class="title">
        <span> {{'Sales Order Type' | translate }}</span>
      </div>
    </div>-->
  <div class="page-body">
    <div class="preview-filter-data" dir="ltr">{{selectedReport.dateRange}}</div>
    <div class="preview-filter-data" dir="ltr">{{selectedReport.locations}}</div>
    <section class="tile" style="margin-top: 20px" [ngClass]="{'lazy': (loading$ | async)}">
      <!--<div class="tile-header">
    <div class="row">
      <div class="col-lg-8 col-md-7 hide-mobile">
        <h6>{{'Sales Order Type Report' | translate }}</h6>
      </div>
      <div class="col-lg-4 col-md-5 col-sm-12 position-relative">
        <span class="show-filter-icon float-left">
          <img class="dots-list" src="assets/images/search.svg" />
        </span>
        <input class="form-control search-filter" placeholder="{{'Filter' | translate }}" type="text" name="searchTerm" [(ngModel)]="service.searchTerm" />
      </div>
    </div>
  </div>-->
      <div class="tile-body p-0 table-responsive ">
        <table class="table table-striped table-custom">
          <thead>
            <tr class="table-header">
              <ng-container *ngIf="(data$ | async) as data">
                <th *ngIf="showLocationColumn$ | async" width="15%">
                  <div ngbDropdown class="d-block nav-item dropdown dropdown-header-filter" [container]="data.length <= 5 ? 'body' : 'table'">
                    <button class="btn btn-outline-filter" ngbDropdownToggle>
                      <span>{{ 'Location' | translate }}</span>
                      <img class="down-aero" ngbDropdownOpen src="assets/images/down-aero.svg">
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdown-header-filter-body" class="dropdown-header-filter-body">
                      <div class="form-check" ngbDropdownToggle>
                        <input type="checkbox" class="form-check-input" [(ngModel)]="checkAllLocations" (ngModelChange)="selectAllLocationFilters($event, locations)">
                        <label class="form-check-label font-blue" (click)="selectAllLocationFilters($event, locations)">
                          {{ 'All Locations' | translate }}
                        </label>
                      </div>
                      <div *ngFor="let location of locations">
                        <div class="form-check" ngbDropdownToggle>
                          <input type="checkbox" class="form-check-input" [(ngModel)]="location.isChecked" (ngModelChange)="filterLocations()">
                          <label class="form-check-label" (click)="filterLocations()">
                            {{ location.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </th>
              </ng-container>
              <th width="20%" sortable="Name" (sort)="onSort($event)">{{'Name' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
              <th width="20%" sortable="PaymentMethod" (sort)="onSort($event)">{{'Payment Method' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
              <th width="15%" sortable="Amount" (sort)="onSort($event)">{{'Amount' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
              <th width="15%" sortable="OrderCount" (sort)="onSort($event)">{{'Orders' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>
              <th width="15%" sortable="Status" (sort)="onSort($event)">{{'Status' | translate }} <img class="d-inline td-sorting-icon" src="assets/images/icons/sorting-icon.png" /></th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of data$ | async ">
              <td *ngIf="showLocationColumn$ | async">
                {{ order.LocationName }}
              </td>
              <td> <div class="item-title"> {{order.Name | translate}}</div> </td>
              <td> {{order.PaymentMethod | translate}} </td>
              <td> {{order.Amount | number:'1.2-2'}} </td>
              <td> {{order.OrderCount}} </td>
              <td style="color: #2d8c9f !important;"> <strong>{{order.Status | translate}} </strong></td>
            </tr>
            @if(service.salesOrdersTypeTotals){
            <tr class="total-row">
              <td>
                <label class="total-title fw-bold font-15">{{ 'Total of' | translate }} <strong> {{ (total$ | async)! }}</strong> {{ 'rows' | translate }}</label>
              </td>
              <td></td>
              <!--<td></td>-->
              <td>{{service.salesOrdersTypeTotals.Amount | number: '1.2-2'}}</td>
              <td>{{service.salesOrdersTypeTotals.Orders}}</td>
              <td></td>
            </tr>
             }
          </tbody>
        </table>
      </div>
      <!--{{'Showing' | translate }}
      <strong>{{(total$ | async)}}</strong>
      {{'results' | translate }}-->
    </section>
    <div class="row mt-10">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p class="pagination-count">
          {{'Showing' | translate }}
          <strong>{{(service.pageSize * service.page)-199}}</strong> {{'to' | translate }}
          <strong>{{(service.pageSize * service.page) > (total$ | async)!?(total$ | async)!:service.pageSize * service.page}}</strong>&nbsp;{{'of' | translate }}
          <strong>{{(total$ | async)!}}</strong>{{'results' | translate }}
        </p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <ngb-pagination class="d-flex justify-content-end" [collectionSize]="(total$ | async)!" [(page)]="service.page" [pageSize]="service.pageSize" [maxSize]="3" [boundaryLinks]="true">
          <ng-template ngbPaginationFirst><img src="../../../assets/images/icons/backward.svg" /> <img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationLast><img src="../../../assets/images/icons/forward.svg" /><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationPrevious><img src="../../../assets/images/icons/backward.svg" /> </ng-template>
          <ng-template ngbPaginationNext><img src="../../../assets/images/icons/forward.svg" /></ng-template>
          <ng-template ngbPaginationEllipsis>...</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
  </div>
</main>
