import { Component, Input, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbdSortableHeader, SortEvent } from '../../_directives/sortable.directive';
import { LocationSalesSummaryAnalyticsDto } from '../../_models/LocationSalesSummaryAnalyticsDto';
import { LocalStorageService } from '../../_services/local-storage.service';
import { LocationService } from '../../_services/location.service';
import { SalesSummaryReportService } from '../../_services/salessummaryreport.service';
import * as myGlobals from '../../_helpers/globals';


@Component({
  selector: 'app-location-sales-sumamry-analytics',
  templateUrl: './location-sales-sumamry-analytics.component.html',
  styleUrl: './location-sales-sumamry-analytics.component.css'
})
export class LocationSalesSumamryAnalyticsComponent {


  data$: Observable<LocationSalesSummaryAnalyticsDto[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  selectedLang = 'en';
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  @Input() fromDate = null;
  @Input() toDate = null;
  @Input() locationID = null;
  @Input() Currency = null;
  @Input() Period = null;

  constructor(public service: SalesSummaryReportService,
    public locationService: LocationService,
    private router: Router,
    private localStorageService: LocalStorageService) {
    this.selectedLang = this.localStorageService.getSelectedLanguage();
  }

  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.loadSalesSummary();
  }
  loadSalesSummary() {
    if (this.fromDate && this.toDate && this.Period) {
      this.service.getLocationSalesSummaryAnalytics(this.localStorageService.getSelectedBrand().BrandID, this.locationID, this.fromDate, this.toDate);
      this.data$ = this.service.data$;
      this.total$ = this.service.total$;
      this.loading$ = this.service.loading$;
    }
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  public getTimeDiff(date: Date) {

    let oToday = new Date();
    let oDatePublished = new Date(date);
    if (oDatePublished.getFullYear() > 2000) {
      let nDiff = oToday.getTime() - oDatePublished.getTime();
      // Get diff in days
      let days = Math.floor(nDiff / 1000 / 60 / 60 / 24);
      nDiff -= days * 1000 * 60 * 60 * 24;

      // Get diff in hours
      let hours = Math.floor(nDiff / 1000 / 60 / 60);
      nDiff -= hours * 1000 * 60 * 60;

      // Get diff in minutes
      let minutes = Math.floor(nDiff / 1000 / 60);
      nDiff -= minutes * 1000 * 60;

      // Get diff in seconds
      let seconds = Math.floor(nDiff / 1000);

      // Render the diffs into friendly duration string

      // Days
      let sDays = '00';
      if (days > 0) {
        sDays = String(days);
      }
      if (sDays.length === 1) {
        sDays = '0' + sDays;
      }

      // Format Hours
      let sHour = '00';
      if (hours > 0) {
        sHour = String(hours);
      }
      if (sHour.length === 1) {
        sHour = '0' + sHour;
      }

      //  Format Minutes
      let sMins = '00';
      if (minutes > 0) {
        sMins = String(minutes);
      }
      if (sMins.length === 1) {
        sMins = '0' + sMins;
      }

      //  Format Seconds
      let sSecs = '00';
      if (seconds > 0) {
        sSecs = String(seconds);
      }
      if (sSecs.length === 1) {
        sSecs = '0' + sSecs;
      }

      let friendlyNiceText;
      let result = {
        value: 0,
        text: ''
      };
      // Set friendly text for printing
      if (days === 0) {

        if (hours === 0) {

          if (minutes === 0) {
            let sSecHolder = seconds > 1 ? 'Seconds' : 'Second';
            friendlyNiceText = seconds + ' ' + sSecHolder;
            result.text = sSecHolder;
            result.value = seconds;
          } else {
            let sMinutesHolder = minutes > 1 ? 'Minutes' : 'Minute';
            friendlyNiceText = minutes + ' ' + sMinutesHolder;
            result.text = sMinutesHolder;
            result.value = minutes;
          }

        } else {
          let sHourHolder = hours > 1 ? 'Hours' : 'Hour';
          friendlyNiceText = hours + ' ' + sHourHolder;
          result.text = sHourHolder;
          result.value = hours;
        }
      } else {
        let sDayHolder = days > 1 ? 'Days' : 'Day';
        friendlyNiceText = days + ' ' + sDayHolder;
        result.text = sDayHolder;
        result.value = days;
      }

      return result;
    }
    else {
      return null;
    }
  }
  openLocationInventory(locationID) {
    this.locationService.setLocationId(locationID);
    this.router.navigate([myGlobals.industryName(this.localStorageService) + "/location/edit", 'inventory']);
  }
}
