import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent, NgbdSortableHeader } from 'src/app/_directives/sortable.directive';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';
import { LocationSalesSummary } from 'src/app/_models/GeneratedReport';
import { Router } from '@angular/router';
import { ExcelService } from '../../../_services/excel.service';
import { LocationSalesSummaryService } from '../../../_services/location-sales-summary.service';
import { DataOperationsService } from '../../../_services/data-operations.service';
import { SortColumn } from '../../../_directives/sortable.directive';

@Component({
  selector: 'app-location-sales-summary',
  templateUrl: './location-sales-summary.component.html',
  styleUrls: ['./location-sales-summary.component.css']
})
export class LocationSalesSummaryComponent implements OnInit {

  data$: Observable<LocationSalesSummary[]>;
  total$: Observable<number>;
  loading$: Observable<boolean>;
  locationSubscription: Subscription;
  allData$: Observable<LocationSalesSummary[]>;


  locations = [];
  checkAllLocations: boolean = false;
  submit: boolean;
  selectedReport: any;
  allSelected: boolean = false;
  sortColumn: SortColumn;
  sortDirection: string;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: LocationSalesSummaryService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
    private router: Router,
    private alertService: AlertService,
    private excelService: ExcelService, private dataOperationsService: DataOperationsService) {
    this.loading$ = service.loading$;
    this.submit = false;

    this.selectedReport = history.state;
    console.log(this.selectedReport);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.getReportData(this.selectedReport.dataURL);
      //this.getReportData("https://marnpossastorage.blob.core.windows.net/marnpos-admin-reports/2021-01-30/stock-20210130T103754521");
    }, 1000);
  }

  getReportData(dataURL) {
    this.service.getSalesDetailReport(dataURL);
    this.data$ = this.service.data$;
    this.total$ = this.service.total$;
    this.loading$ = this.service.loading$;
    this.allData$ = this.service.allData$;


    this.service.allData$.subscribe(res => {
      this.locations = [];
      if (res) {
        new Set(res.map(report => report.LocationName)).forEach(e => {
          this.locations.push(
            {
              name: e,
              isChecked: false
            }
          )
        });
      }
    });
  }
  onSort({ column, direction }: SortEvent) {

    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortColumn = column;
    this.sortDirection = direction;

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  ngOnDestroy() {
    this.service.clear();
    this.alertService.clear();

    if (this.locationSubscription != undefined)
      this.locationSubscription.unsubscribe();
  }
  export(type, data): void {
    this.excelService.exportAsExcelFile(this.getExportData(data), 'Export-LocationSales', type);
  }
  private getExportData(data) {
    let selected = [];

    const dataRows = data.source._value;
    const hasSelected = dataRows.some(row => row.selected === true);

    if (!hasSelected) {
      this.allSelected = true;
      dataRows.forEach(row => row.selected = true);   
    }
    else {
      dataRows.forEach(d => {
        if (d.selected) {
          selected.push(d);
        }
      });
    }

    selected = selected?.length == 0 ? dataRows : selected;

    selected = this.dataOperationsService.prepareExportData(selected, this.sortColumn, this.sortDirection);

    return selected;
  }

  checkSelection(data): void {
    this.allSelected = data.source._value.every(item => item.selected);
  }
  public bulkSelection($event) {
    this.data$.forEach(i => i?.forEach(e => e.selected = $event.target.checked));
    this.allData$.forEach(i => {
      if (i)
        i.forEach(e => e.selected = $event.target.checked)
    });
  }


  filterLocations() {
    if (this.locations.filter(e => e.isChecked === false)?.length > 0) {
      this.checkAllLocations = false;
    }
    else {
      this.checkAllLocations = true;
    }
    let headerFilter = {
      locations: this.locations.filter(k => { if (k.isChecked) { return k.name } }).map(function (k) { return k.name }),
    }
    this.service.headerFilter = headerFilter;
  }


  public selectAllLocationFilters($event, object: any[]) {
    object.forEach(e => e.isChecked = $event);
    this.filterLocations();
  }

}
