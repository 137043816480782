
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators, UntypedFormArray, AbstractControl } from '@angular/forms';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AlertService } from 'src/app/_services/alert.service';

import { LocationService } from 'src/app/_services/location.service';
import { ItemService } from '../../../_services/item.service';
import { DiscountService } from '../../../_services/discounts.service';
import { CommonService } from '../../../_services/common.service';
import { NgbdDatepickerAdapter } from '../../../datepicker/datepicker-adapter';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Console } from 'console';
import { TranslateService } from '@ngx-translate/core';
import { timeRangeValidator } from '../../../CustomValidators/TimeRangeValidator';
import { dateRangeValidator } from '../../../CustomValidators/DateRangeValidator';



interface DayOfWeek {
  id: number;
  name: string;
  selected: boolean;
  startTime: string;
  endTime: string;
}
interface FullTime {
  time: Time,
  meridian: boolean
};
interface Time {
  hour: number;
  minute: number;
}

@Component({
  selector: 'app-add-discount',
  templateUrl: './add-discount.component.html',
  styleUrls: ['./add-discount.component.css']
})

export class AddDiscountComponent implements OnInit {
  @Input() name;
  @Input() SelectedID = 0;
  @Input() SelectedName = '';
  submitted = false;
  activeTime = false;
  discountsetupForm: UntypedFormGroup;
  loading = false;
  ButtonText = "Save";
  Title = "New Discount";
  isTimeActiveLabel: string;
  Locations: any;
  Items = [];
  selectedItems = [];
  selectedLocations = [];

  selectedValues = [];
  ApplicableOn = [
    { name: 'Orders', id: 'Orders' },
    { name: 'Items Or Categories', id: 'Items' }
  ]

  Types = [
    { name: 'Amount', id: 'Amount' },
    { name: 'Percentage', id: 'Percentage' },
  ]
  daysGroup: any;
  DiscountApplied = [
    { name: 'Yes', id: true },
    { name: 'No', id: false },
  ]
  Currency: any;

  @ViewChild(NgbdDatepickerAdapter, { static: true }) dateComp;
  arrItems: any[] = [];
  modelStartDate: NgbDateStruct;
  modelEndDate: NgbDateStruct;
  placement = 'bottom';

  currentdate: any;;

  daysOfWeek: DayOfWeek[];
  activeDaysValues: DayOfWeek[];
  invalidDateSelection: boolean = false
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private alertService: AlertService,
    private services: DiscountService,
    private location: LocationService,
    public setting: CommonService
  ) {

    this.daysOfWeek = [
      { id: 1, name: 'Sunday', selected: false, startTime: "00:00", endTime: "23:59" },
      { id: 2, name: 'Monday', selected: false, startTime: "00:00", endTime: "23:59" },
      { id: 3, name: 'Tuesday', selected: false, startTime: "00:00", endTime: "23:59" },
      { id: 4, name: 'Wednesday', selected: false, startTime: "00:00", endTime: "23:59" },
      { id: 5, name: 'Thursday', selected: false, startTime: "00:00", endTime: "23:59" },
      { id: 6, name: 'Friday', selected: false, startTime: "00:00", endTime: "23:59" },
      { id: 7, name: 'Saturday', selected: false, startTime: "00:00", endTime: "23:59" },
    ];

    this.createForm();
    this.loadSettings();
    this.loadLocations();
    this.loadItems(parseInt(this.localStorageService.getSelectedBrand().BrandID));

    const current = new Date();
    this.currentdate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    //this.updateTimeLabel();
  }

  ngOnInit() {
   
  }

  ngAfterViewInit() {

  }
  get f() { return this.discountsetupForm.controls; }

  private createForm() {
    this.discountsetupForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]{3,}( \w+)*$/)]],
      arabicName: ['', [Validators.required, Validators.pattern('^[ء-ي]{3,}( [0-9ء-ي]+)*$')]],
      applicableOn: ['Orders', Validators.required],
      discountApplied: [true, [Validators.required]],
      type: ['Amount', Validators.required],
      amount: [, Validators.required],
      priceCap: [],
      period: [false, Validators.required],
      values: ['', Validators.required],
      startDate: [''],
      endDate: [''],
      timeIsActive: [false, Validators.required],
      statusID: [true],
      brandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      rowVersion: 0,
      discountID: 0,
      days: []
    });
  };

  //createDayFormGroup(day: any) {
  //  return this.formBuilder.group({
  //    id: day.id,
  //    name: day.name,
  //    selected: day.selected,
  //    startTime: [{ value: day.startTime, disabled: !day.selected }, Validators.required],
  //    endTime: [{ value: day.endTime, disabled: !day.selected }, Validators.required]
  //  }, { validators: timeRangeValidator });
  //}
  //get days(): UntypedFormArray {
  //  return this.discountsetupForm.get('days') as UntypedFormArray;
  //}

  //onCheckboxChange(index: number): void {
  //  const dayGroup = this.f.days.value;
  //  const isSelected = dayGroup.selected.value;
  //  if (isSelected) {
  //    dayGroup.startTime.enable();
  //    dayGroup.endTime.enable();
  //  } else {
  //    dayGroup.startTime.disable();
  //    dayGroup.endTime.disable();
  //  }
  //}
  disableTimesInitially() {
    this.f.days.value.forEach(dayControl => {
      const selected = dayControl.selected.value;
      if (!selected) {
        dayControl.startTime.disable();
        dayControl.endTime.disable();
      }
    });
  }

  removeWeeklyPeriodSelection(): void {
    this.f.days.value.forEach(dayControl => {
      dayControl.selected.setValue(false);
    });
    //const dayGroup = this.days.at(index) as UntypedFormGroup;
    //const isSelected = dayGroup.get('selected').value;
    //if (isSelected) {
    //  dayGroup.get('startTime').enable();
    //  dayGroup.get('endTime').enable();
    //} else {
    //  dayGroup.get('startTime').disable();
    //  dayGroup.get('endTime').disable();
    //}
  }

  //changeOpenTimePeriod() {
  //  const timeIsActive = this.f.timeIsActive.value;
  //  const daysArray = this.f.days as UntypedFormArray;
  //  // If the checkbox is checked, enable the time table
  //  daysArray.controls.forEach((dayControl: UntypedFormGroup) => {
  //    if (timeIsActive) {
  //      this.activeTime = true;
  //      // Enable all controls for editing
  //      dayControl.selected.enable();
  //      dayControl.id.enable();
  //      dayControl.name.enable();
  //      dayControl.startTime.enable();
  //      dayControl.endTime.enable();

  //      this.disableTimesInitially();
  //      //this.updateTimeLabel();
  //    } else {
  //      this.activeTime = false;
  //      // Disable all controls
  //      dayControl.selected.disable();
  //      dayControl.id.disable();
  //      dayControl.name.disable();
  //      dayControl.startTime.disable();
  //      dayControl.endTime.disable();
  //      //this.updateTimeLabel();
  //      this.removeWeeklyPeriodSelection();
  //    }
  //  });
  //}
  //updateTimeLabel() {
  //  const timeIsActive = this.discountsetupForm.get('timeIsActive').value;
  //  if (timeIsActive) {
  //    this.isTimeActiveLabel = this.translate.instant('TimeIsActive');
  //  } else {
  //    this.isTimeActiveLabel = this.translate.instant('TimeIsInActive');
  //  }
  //}

  setSelectedDiscount() {

    if (this.SelectedID !== 0) {
      this.ButtonText = "Save";
      this.loading = true;

      this.services.getById(this.SelectedID).subscribe(res => {
        //console.log(res);
        this.Title = "Edit Discount";
        //Set Forms
        this.editForm(res);
        this.loading = false;


        //this.arrItems = [];
        //let _this = this;
        //this.selectedValues.forEach(function (selItem) {
        //   _this.Items.filter(function (item) {
        //     if (selItem == item.itemID) {
        //      _this.arrItems.push(item )
        //    }
        //  });

        //});


      });
    }
  }

  private loadLocations() {
    this.location.getAllLocationsddl(this.f.brandID.value).subscribe((res: any) => {
      this.Locations = res;
    });
  }

  private loadItems(brandId) {
    this.services.getAllItemsddl(brandId).subscribe((res: any) => {
      res = res.map(obj => ({ ...obj, disabled: obj.discountID && ((this.SelectedID && obj.discountID != obj.discountID) || this.SelectedID === 0)?  true:false }));
      this.Items = res;
      this.setSelectedDiscount();
    });
  }

  private loadSettings() {
    this.setting.getCurrencySettings(this.localStorageService.getSelectedBrand().BrandID).subscribe((res: any) => {
      this.Currency = res.currency;
    });

  }

  private editForm(value) {
    //debugger


    this.f.discountID.setValue(value.discountID);
    this.f.name.setValue(value.name);
    this.f.arabicName.setValue(value.arabicName);
    this.f.applicableOn.setValue(value.levels);
    this.f.discountApplied.setValue(value.isDiscountApplied);
    this.f.type.setValue(value.type);
    this.f.amount.setValue(value.amount);
    this.f.priceCap.setValue(value.priceCap);
    this.f.period.setValue(!value.openPeriod);
    this.changeOpenPeriod();

    this.f.brandID.setValue(value.brandID);
    this.f.statusID.setValue(value.statusID === 1 ? true : false);
    this.f.rowVersion.setValue(value.rowVersion);
    this.f.values.setValue(value.values);

    this.f.startDate.setValue(value.startDateTime);
    this.f.endDate.setValue(value.endDateTime);

    this.f.startDate.setValue({ day: new Date(value.startDateTime).getDate(), month: new Date(value.startDateTime).getMonth() + 1, year: new Date(value.startDateTime).getFullYear() });
    this.f.endDate.setValue({ day: new Date(value.endDateTime).getDate(), month: new Date(value.endDateTime).getMonth() + 1, year: new Date(value.endDateTime).getFullYear() });
    this.selectedValues = value.values.split(',').map(Number);
    this.selectedItems = value.values.split(',').map(Number);
    this.selectedLocations = value.values.split(',').map(Number);

    if (value.itemDiscounts)
      this.arrItems = this.Items.filter((el) => {
        return value.itemDiscounts.some((f) => {
          return f.itemID === el.itemID;
        });
      });

    this.f.applicableOn.disable();
    this.setDaysValues(value.weeklyTimeSchedule);
  }

  setDaysValues(days: any[]): void {

    if (days.length > 0) {
      this.f.timeIsActive.setValue(true);
      days.forEach((d) => {
        this.daysOfWeek.forEach((day, index) => {
          if (d.id == day.id) {
            day.startTime = d.startTime;
            day.endTime = d.endTime;
            day.selected = true;
          }
        });
      });

    }
  }

  close() {
    this.activeModal.dismiss();
  }





  selectedItem(obj) {
    //console.log(obj)
    this.arrItems.push(obj);
  }


  selectItems(obj) {
    if (obj instanceof Array) {
      this.arrItems = obj.filter(e => e.disabled == false);
    }
  }
  selectedItemCheck(obj) {

    if (obj.selected === true)
      this.arrItems.push(obj.value);
    else {
      this.arrItems = this.arrItems.filter(function (item) {

        return item.itemID !== obj.value.itemID
      })
    }
  }

  selectedCategoryCheck(obj) {
    //console.log(obj);
    //console.log(this.selectedItems);

    if (obj.selected === true) {
      var itemToAdd = this.Items.filter(item => item.subCategoryName === obj.value.subCategoryName);

      //console.log(itemToAdd);

      for (var i = 0; i < itemToAdd.length; i++) {
        var alreadyExists = this.arrItems.filter(function (item) {
          return item.itemID === itemToAdd[i].itemID;
        }).length;

        if (alreadyExists === 0)
          this.arrItems.push(itemToAdd[i]);
      }

    }
    else {
      this.arrItems = this.arrItems.filter(function (item) {
        return item.subCategoryName !== obj.value.subCategoryName
      })
    }
  }


  deleteRow(obj) {
    const index = this.arrItems.indexOf(obj);
    this.arrItems.splice(index, 1);

    if (this.arrItems.length == 0) {
      this.f.values.setValue('');
      this.selectedValues = [];;
    }
  }

  changeOpenPeriod() {
    if (this.f.period.value === true) {
      //this.modelStartDate = { day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear() };
      //this.modelEndDate = { day: new Date().getDate(), month: new Date().getMonth() + 1, year: new Date().getFullYear() };
      this.modelStartDate = null;
      this.modelEndDate = null;
      this.f.startDate.setValidators([Validators.required]);
      this.f.endDate.setValidators([Validators.required]);
      this.f.startDate.updateValueAndValidity();
      this.f.endDate.updateValueAndValidity();

    }
    else {
      this.f.startDate.clearValidators();
      this.f.endDate.clearValidators();
      this.f.startDate.updateValueAndValidity();
      this.f.endDate.updateValueAndValidity();

    }
  }

  getWeeklyTimeSchedule() {
    let daysValue: DayOfWeek[] = [];
    let validate: boolean = true;
    this.daysOfWeek.forEach((day) => {
      if (day.selected) {
        if (day.startTime && day.endTime && day.startTime < day.endTime) {

          daysValue.push(day);
        }
        else {
          validate = false;
        }
      }
    });
    let ret = {
      daysValue: daysValue,
      validate: validate
    }
    return ret;
  }

  public onSelectAll(control) {
    if (control == 'location') {
      const _selectedLocations = this.Locations.map(item => item.locationID);
      this.f.values.patchValue(_selectedLocations);
    }

  }
  closeSelect(select: NgSelectComponent) { select.close(); }

  onSubmit() {

    if (this.f.applicableOn.value == "Items") {
      
      this.selectedValues = this.selectedItems;
      //this.selectedValues = [];
      //for (var i = 0; i < this.arrItems.length; i++) {
      //  this.selectedValues.push(this.arrItems[i].itemID);
      //}
    }

    else {
      this.selectedValues = this.selectedLocations;
    }
    this.f.values.setValue(this.selectedValues);
    this.discountsetupForm.markAllAsTouched();
    this.submitted = true;
    this.alertService.clear();
    if (this.discountsetupForm.invalid) { return; }




    let startdate = null, enddate = null;
    if (this.f.period.value === true) {
      startdate = new Date(this.f.startDate.value.year + "/" + this.f.startDate.value.month + "/" + this.f.startDate.value.day + ", " + new Date().toTimeString());
      enddate = new Date(this.f.endDate.value.year + "/" + this.f.endDate.value.month + "/" + this.f.endDate.value.day + ", " + new Date().toTimeString());
    }

    if (startdate > enddate) {
      this.invalidDateSelection = true;
      return;
    }

    //console.log(JSON.stringify(this.discountsetupForm.value));
    let weekDays = this.getWeeklyTimeSchedule();
    if (!weekDays.validate) {
      return;
    }

    var postData = {
      DiscountID: this.f.discountID.value,
      Name: this.f.name.value,
      ArabicName: this.f.arabicName.value,
      Type: this.f.type.value,
      Amount: parseFloat(this.f.amount.value),
      PriceCap: parseFloat(this.f.priceCap.value),
      StartDateTime: startdate,
      EndDateTime: enddate,
      Levels: this.f.applicableOn.value,
      IsDiscountApplied: this.f.discountApplied.value,
      OpenPeriod: !this.f.period.value,
      BrandID: parseInt(this.localStorageService.getSelectedBrand().BrandID),
      StatusID: this.f.statusID.value === true ? 1 : 2,
      Values: this.selectedValues.toString(),
      rowVersion: this.f.rowVersion.value,
      weeklyTimeSchedule: weekDays.daysValue
    }

    //console.log(JSON.stringify(postData));

    this.loading = true;
    if (parseInt(this.f.discountID.value) === 0) {
      this.services.insert(postData).subscribe(data => {
        this.alertService.success("Discount has been created");
        this.loading = false;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    } else {
      this.services.update(postData).subscribe(data => {
        this.alertService.success("Discount has been Updated");
        this.loading = false;
        this.SelectedID = 0;
        this.activeModal.dismiss();
      }, error => {
        this.alertService.error(error);
        this.loading = false;
      });
    }
  }
}

