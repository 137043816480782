<form [formGroup]="discountsetupForm" class="form discountForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Discount' :SelectedName )  | translate}}  </h4>
    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" *hasPermission="['Quick Actions','Discounts',(SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText  | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4" style="margin-top: 40px;">
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Name">{{'Discount Name English' | translate }}</label>
              <input type="text" class="form-control" id="name" placeholder="" formControlName="name">
              <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="alert alert-danger">
                <div *ngIf="f.name.errors.required">
                  {{'Name is required' | translate }} .
                </div>
                <div *ngIf="f.name.errors.pattern">
                  {{ 'English Discount Name Pattern' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="arabicName">{{'Discount Name Arabic' | translate }}</label>
              <input type="text" class="form-control" id="arabicName" placeholder="" formControlName="arabicName">
              <div *ngIf="f.arabicName.invalid && (f.arabicName.dirty || f.arabicName.touched)" class="alert alert-danger">
                <div *ngIf="f.arabicName.errors.required">
                  {{'Arabic Name is required' | translate }} .
                </div>
                <div *ngIf="f.arabicName.errors.pattern">
                  {{ 'Arabic Discount Name Pattern' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Calculation">{{'Discount Type' | translate }}</label>
              <select class="custom-select form-control" formControlName="type">
                <option [ngValue]="option.id" selected="option.id == type" *ngFor="let option of Types">
                  {{option.name | translate}}
                </option>
              </select>
              <div *ngIf="f.type.invalid && (f.type.dirty || f.type.touched)" class="alert alert-danger">
                <div *ngIf="f.type.errors.required">
                  {{'Discount type is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="f.type.value == 'Amount'">
            <div class="form-group col-lg-12">
              <label for="amount">{{'Discount Amount' | translate }}</label>
              <div class="input-group">
                <input type="text" class="form-control" id="amount" min="0" pattern="\+?(\d+(\.(\d+)?)?|\.\d+)" placeholder="{{'Add Price' | translate}}" formControlName="amount">
                <div class="input-group-append">
                  <span class="input-group-text" id="inputGroup-sizing-default">{{Currency | translate}}</span>
                </div>
              </div>
              <div *ngIf="f.amount.invalid && (f.amount.dirty || f.amount.touched)" class="alert alert-danger">
                <div *ngIf="f.amount.errors.required">
                  {{'Discount Amount is required' | translate }} .
                </div>
                <div *ngIf="f.amount.errors && (f.amount.dirty || f.amount.touched)">
                  <div [hidden]="!f.amount.errors.pattern">
                    {{'Amount should be greater than 0' | translate }}.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="f.type.value == 'Percentage'">
            <div class="form-group col-lg-12">
              <label for="amount">{{'Percentage' | translate }}</label>
              <div class="input-group">
                <input type="text" class="form-control" id="amount" max="100" pattern="^$|^([0-9]|[1-9][0-9]|[1][0][0])?" placeholder="" formControlName="amount">
                <div class="input-group-append">
                  <span class="input-group-text" id="inputGroup-sizing-default">{{'%' | translate}}</span>
                </div>
              </div>
              <div *ngIf="f.amount.invalid && (f.amount.dirty || f.amount.touched)" class="alert alert-danger">
                <div *ngIf="f.amount.errors.required">
                  {{'Percentage is required' | translate }} .
                </div>
                <div *ngIf="f.amount.errors && (f.amount.dirty || f.amount.touched)">
                  <div [hidden]="!f.amount.errors.pattern">
                    {{'Percentage should be between 0 and 100' | translate }}.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="f.type.value == 'Percentage'">
            <div class="form-group col-lg-12">
              <label for="priceCap">{{'Maximum Amount' | translate }}</label>
              <div class="input-group">

                <input type="text" class="form-control" id="priceCap" placeholder="" formControlName="priceCap">
                <div class="input-group-append">
                  <span class="input-group-text" id="inputGroup-sizing-default">{{Currency | translate}}</span>
                </div>
              </div>
              <div *ngIf="f.priceCap.invalid && (f.priceCap.dirty || f.priceCap.touched)" class="alert alert-danger">
                <div *ngIf="f.priceCap.errors.required">
                  {{'Price Cap is required' | translate }} .
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Calculation">{{'Apply this discount instantly' | translate }}</label>
              <select class="custom-select form-control" formControlName="discountApplied">
                <option [ngValue]="option.id" selected="option.id == discountApplied" *ngFor="let option of DiscountApplied">
                  {{option.name | translate}}
                </option>
              </select>
              <div *ngIf="f.discountApplied.invalid && (f.discountApplied.dirty || f.discountApplied.touched)" class="alert alert-danger">
                <div *ngIf="f.discountApplied.errors.required">
                  {{'Discount Applied is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Calculation">{{'Applied On' | translate }}</label>
              <select class="custom-select form-control custom-select-2" formControlName="applicableOn" (change)="selectedValues=[]" [class.disabled]="SelectedID !== 0">
                <option [ngValue]="option.id" selected="option.id == applicableOn" *ngFor="let option of ApplicableOn">
                  {{option.name | translate}}
                </option>
              </select>
              <div *ngIf="f.applicableOn.invalid && (f.applicableOn.dirty || f.applicableOn.touched)" class="alert alert-danger">
                <div *ngIf="f.applicableOn.errors.required">
                  {{'Applicable On is required' | translate }}.
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="f.applicableOn.value == 'Items'">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Country">{{'Select Items' | translate }}</label>

              <ng-select [items]="Items" class="po-box-autocomplete border-right-radius-unset "
                         groupBy="subCategoryName"
                         [selectableGroup]="true"
                         [selectableGroupAsModel]="false"
                         bindLabel="name"
                         bindValue="itemID"
                         [closeOnSelect]="false"
                         [multiple]="true"
                         placeholder="Search"
                         [(ngModel)]="selectedItems"
                         [ngModelOptions]="{standalone: true}"
                         (change)="selectItems($event)">

                <ng-template ng-multi-label-tmp let-items="items">
                  <!--<div class="ng-value" *ngFor="let item of items">
      <span class="ng-value-label">{{item.name}}</span>
      <span class="ng-value-icon right" style="border-left: 1px solid #000;" (click)="clear(item)" aria-hidden="true">×</span>
    </div>-->
                </ng-template>

                <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" >
                  <div class="row d-flex align-items-center p-2">
                    <input type="checkbox" class="form-check-inline col-1" [checked]="item$.selected" />
                    <label class="col-11 m-0">

                      {{ item.subCategoryName || 'Unnamed group' }}
                    </label>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                  <div class="row d-flex align-items-center p-2">
                    <input type="checkbox" class="form-check-inline col-1" [checked]="item$.selected" [disabled]="item.disabled" />
                    <label [ngClass]="{'opacity-50':item.disabled}" class="col-5 m-0">

                      {{item.name}}
                    </label>
                    @if(item.disabled){
                    <label class="move-to-end opacity-50 col-6 m-0">{{'Used in Another Discount' | translate }}</label>
                    }
                  </div>
                </ng-template>
                
              </ng-select>
              <!--<div *ngIf="f.values.invalid && (f.values.dirty || f.values.touched)" class="alert alert-danger">
                <div *ngIf="f.values.errors && (f.values.dirty || f.values.touched)">
                  <div *ngIf="!arrItems || arrItems.length === 0">
                    {{'Items Required' | translate }}.
                  </div>
                </div>
              </div>-->

              <div *ngIf="f.applicableOn.value == 'Items' && (!selectedItems || selectedItems.length === 0)" class="alert alert-danger">
                {{'Items Required' | translate }}.
              </div>
            </div>
            <section class="tile" style="border:none;box-shadow:none">
              <div class="p-0 table-responsive ">
                <table class="table table-striped discount-table">
                  <thead>
                    <tr class="disount-item-table-header">
                      <th class="p-0" scope="col" width="5%"><img src="/assets/images/icons/v2/camera.svg" /></th>
                      <th scope="col" width="50%" class="text-start">{{'Item Name' | translate }}</th>
                      <th scope="col" width="10%" class=" text-start">{{'Price' | translate }}</th>
                      <th scope="col" width="35%" class="text-start">{{'Discount Price' | translate }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of arrItems">
                      <td class="p-0">
                        <img [src]="item.itemImage" *ngIf="(item.itemImage !== '' && item.itemImage !== null)" class="table-img" alt="image" onError="this.src='https://marnpossastorage.blob.core.windows.net/marnpos-v2-images/default-product.PNG'" />
                        <img *ngIf="(item.itemImage === '' || item.itemImage === null)" src="../../../../assets/images/icons/discount-item-image.svg" class="table-img" alt="" />
                      </td>
                      <td scope="row" class="font-weight-bold font-blue  text-start">{{item.name}}</td>
                      <td scope="row" class="font-weight-bold  text-start">{{item.price}}</td>
                      <td *ngIf="f.type.value == 'Amount'" scope="row" class="font-weight-bold font-blue  text-start">{{ item.price - f.amount.value < 0 ? 0 : item.price - f.amount.value }}</td>
                      <td *ngIf="f.type.value == 'Percentage'" scope="row" class="font-weight-bold font-blue  text-start">{{ item.price}}</td>
                    </tr>
                    <tr *ngIf="arrItems && arrItems.length === 0">
                      <td colspan="10">
                        <div class="row p-1">
                          <div class="col-12 text-center">
                            <h6 class="pt-3 pb-3">{{'No Items!' | translate }}</h6>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
          <div class="row">
            <!--*ngIf="f.applicableOn.value == 'Orders'"-->
            <div class="form-group col-lg-12 col-sm-12" *ngIf="f.applicableOn.value == 'Orders'">
              <label for="Country">{{'Locations' | translate }}</label>
              <ng-select [items]="Locations" #select bindLabel="name" class="custom" bindValue="locationID" [multiple]="true"
                         placeholder="" [(ngModel)]="selectedLocations" [ngModelOptions]="{standalone: true}">
                <ng-template ng-header-tmp>
                  <div (click)="closeSelect(select)">
                    <button class="dropdown-item"
                            (click)="onSelectAll('location')">
                      {{'Select All' | translate }}
                    </button>
                  </div>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div class="ng-value" *ngFor="let item of items">
                    <span class="ng-value-label">{{item.name}}</span>
                    <span class="ng-value-icon right" style="border-left: 1px solid #1f2532;"
                          (click)="clear(item)" aria-hidden="true">×</span>
                  </div>
                </ng-template>
              </ng-select>

              <div *ngIf="f.applicableOn.value == 'Orders' && (!selectedLocations || selectedLocations.length === 0)" class="alert alert-danger">
                {{'Location Required' | translate }}.
              </div>
            </div>
          </div>
          <div class="period-box">
            <div class="tiltle-bar- form-group">
              <div class="row">
                <div class="col-8">
                  <label for="period">{{'Date Range' | translate }}</label>
                  <p class="font-12 m-0">
                    {{'Set the dates this discount is available' | translate}}
                  </p>
                </div>
                <div class="col-4 text-end px-1">
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="period" formControlName="period" (change)="changeOpenPeriod()">
                    <label class="form-check-label" for="period"></label>

                  </div>
                </div>
              </div>
            </div>

          </div>



          <div class="row">
            <div class="col-lg-12 form-group">
              <div class="row" *ngIf="f.period.value">
                <div class="col-lg-12">
                  <label for="startDates">{{'Start Date' | translate }}</label>
                  <div class="form-group" style="padding:0px">
                    <div class="input-group">
                      <input class="form-control" placeholder="yyyy-mm-dd"
                             name="dp" ngbDatepicker #d="ngbDatepicker" [minDate]="currentdate"
                             formControlName="startDate" [readonly]="true">
                      <div class="input-group-append"><div class="btn-datepicker" (click)="d.toggle()"><img alt="Calender" src="/assets/images/calendar.svg"></div></div>
                    </div>
                  </div>
                  <div *ngIf="f.startDate.invalid && (f.startDate.dirty || f.startDate.touched)" class="alert alert-danger">
                    <div *ngIf="f.startDate.errors.required">
                      {{'Start Date is required' | translate }} .
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="f.period.value">
                <div class="col-lg-12">
                  <label for="endDates">{{'End Date' | translate }}</label>
                  <div class="col-lg-12">
                    <div class="input-group">
                      <input class="form-control" placeholder="yyyy-mm-dd"
                             name="dp" ngbDatepicker #d="ngbDatepicker" [minDate]="currentdate"
                             formControlName="endDate" [readonly]="true">
                      <div class="input-group-append"><div class="btn-datepicker" (click)="d.toggle()"><img alt="Calender" src="/assets/images/calendar.svg"></div></div>
                    </div>
                  </div>
                  <div *ngIf="f.endDate.invalid && (f.endDate.dirty || f.endDate.touched)" class="alert alert-danger">
                    <div *ngIf="f.endDate.errors.required">
                      {{'End Date is required' | translate }} .
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="invalidDateSelection" class="alert alert-danger">
                {{ 'End Date Discount Validators' | translate }}
              </div>
            </div>
          </div>

          <!--
            Time Section
          -->

          <div class="period-box">
            <div class="tiltle-bar- form-group">
              <div class="row">
                <div class="col-8">
                  <label for="timeIsActive" style="margin-bottom: 0; line-height: 29px;">{{ 'Weekly Time Schedule' | translate}}</label>
                  <p class="font-12 m-0">
                    {{'Set the days and hours when the discount will be available' | translate}}
                  </p>
                </div>
                <div class="col-4 text-end px-1">
                  <div class="form-check form-switch">
                    <input type="checkbox" class="form-check-input" id="timeIsActive" formControlName="timeIsActive">
                    <label class="form-check-label" for="timeIsActive"></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- time-table.component.html -->
          <!-- Assuming this is within your Angular component template (e.g., add-discount.component.html) -->
          <div class="row">
            <div class="col-lg-12 form-group">
              <div class="row" *ngIf="f.timeIsActive.value">
                <div class="col-lg-12">
                  <div class="container">
                    <table class="table table-border text-start">
                      <thead>
                        <tr class="table-header">
                          <th></th>
                          <th><label for="startDates">{{ 'Day' | translate }}</label></th>
                          <th><label for="startTimes">{{ 'Discount Starting time' | translate }}</label></th>
                          <th><label for="endTimes">{{ 'Discount Ending time' | translate }}</label></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let day of daysOfWeek ; let i = index">
                          <td>
                            <input type="checkbox" [disabled]="!f.timeIsActive.value" [(ngModel)]="day.selected" [ngModelOptions]="{standalone: true}" [ngClass]="{'disabled':!f.timeIsActive.value}" [checked]="day.selected" #IsSelected>
                          </td>
                          <td><label>{{ day.name }}</label></td>
                          <td>
                            <input type="time" class="form-control custom-timepicker" [(ngModel)]="day.startTime" [ngModelOptions]="{standalone: true}" [ngClass]="{'disabled':!f.timeIsActive.value}" [disabled]="!day.selected && !f.timeIsActive.value" id="startTime-{{i}}">
                            <div *ngIf="day.selected && !day.startTime" class="alert alert-danger">
                              {{'Open Time is required' | translate}}.
                            </div>
                          </td>
                          <td>
                            <input type="time" class="form-control custom-timepicker" [(ngModel)]="day.endTime" [ngModelOptions]="{standalone: true}" [ngClass]="{'disabled':!f.timeIsActive.value}" [disabled]="!day.selected&& !f.timeIsActive.value" id="endTime-{{i}}">
                            @if((day.selected && !day.endTime) || day.startTime> day.endTime){
                            <div class="alert alert-danger">
                              @if(day.selected && !day.endTime){
                              {{'Close Time is required' | translate}}.
                              }
                              @if(day.startTime> day.endTime){
                              {{'Close Time must be after Open Time' | translate}}.
                              }
                            </div>
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group  col-lg-12">
              <div class="status-activebar">
                <label for="Status">

                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.875 18.125H13.125" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 15.625V11.25" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5 8.75L10 11.25L12.5 8.75" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.14873 13.0478C5.40556 12.4674 4.80363 11.726 4.38818 10.8795C3.97273 10.033 3.75457 9.10328 3.7501 8.16031C3.73138 4.77263 6.4625 1.95653 9.8493 1.87681C11.1617 1.84521 12.4507 2.22765 13.5335 2.96987C14.6164 3.71209 15.438 4.77641 15.8819 6.01187C16.3257 7.24732 16.3694 8.59118 16.0065 9.85281C15.6437 11.1144 14.8928 12.2298 13.8604 13.0407C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5294L13.125 15C13.125 15.1658 13.0592 15.3248 12.9419 15.442C12.8247 15.5592 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5592 7.05805 15.442C6.94084 15.3248 6.87499 15.1658 6.87499 15L6.87498 14.5289C6.87427 14.2431 6.80851 13.9612 6.68268 13.7046C6.55685 13.448 6.37425 13.2234 6.14873 13.0478V13.0478Z" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{'Discount status.' | translate }}
                </label>

                <div class="form-check form-switch">
                  <span class="inactive" (click)="f.statusID.setValue(false)" [className]="f.statusID.value==false ? 'active' : 'inactive'" role="button"> {{'Inactive' | translate}}</span>
                  <span class="inactive active" (click)="f.statusID.setValue(true)" [className]="f.statusID.value==true ? 'active' : 'inactive'" role="button">{{'Active' | translate}}</span>
                </div>

              </div>
            </div>
          </div>

          <!--<div class="row">
            <div class="col-lg-12 form-group ">
              <label for="Group Status">{{'Status' | translate }} </label>
              <div class="form-check form-switch" style="float: unset;">
                <input type="checkbox" class="form-check-input" checked id="statusID" formControlName="statusID">
                <label class="form-check-label" for="statusID"></label>
              </div>
            </div>
          </div>-->
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</form>
