<form [formGroup]="deviceForm" class="form" >
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">{{ ((SelectedName === '') ? 'New Device' :SelectedName) | translate}}  </h4>
    <button [disabled]="loading" class="btn btn-primary cutom-button custom-create-btn" *hasPermission="['Setup','Devices', (SelectedID > 0) ? 'Edit' : 'Insert']">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      {{ButtonText | translate}}
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4" style="margin-top: 40px;">

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="refCode">{{'Reference Code' | translate }}</label>
              <input type="text" class="form-control" id=refCode placeholder="" formControlName="refCode">
              <div *ngIf="f.refCode.invalid && (f.refCode.dirty || f.refCode.touched)" class="alert alert-danger">
                <div *ngIf="f.refCode.errors.required">
                  {{'Reference Code is required' | translate }} .
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="terminalName">{{'Device Name' | translate }}</label>
              <input type="text" class="form-control" id=terminalName placeholder="" formControlName="terminalName">
              <div *ngIf="f.terminalName.invalid && (f.terminalName.dirty || f.terminalName.touched)" class="alert alert-danger">
                <div *ngIf="f.terminalName.errors.required">
                  {{'Name is required' | translate }}.
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Device Type">{{'Device Type' | translate }}</label>
              <select class="custom-select form-control custom-select-2" formControlName="deviceTypeID" [attr.disabled]="(f.terminalID.value)? true : null" [class.disabled]="SelectedID !== 0" (change)="loadLicenses()">
                <option value=0>
                  {{'Select Device Type' | translate }}
                </option>
                @for(option of DeviceTypes; track option.deviceTypeID){
                @if((industry !== 1 && SelectedID === 0 && option.deviceTypeID === 1) || (industry !== 1 && SelectedID !== 0 || industry === 1)){
                <option [ngValue]="option.deviceTypeID" [selected]="option.deviceTypeID == f.deviceTypeID.value">
                  {{option.deviceType}}
                </option>
                }
                }
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="Location">{{'Location' | translate }}</label>
              <select class="custom-select form-control custom-select-2" formControlName="locationID" [attr.disabled]="f.terminalID.value? true : null" [class.disabled]="f.terminalID.value">
                <option [ngValue]="option.locationID" selected="option.locationID == locationID"
                        *ngFor="let option of Locations">
                  {{option.name}}
                </option>
              </select>
              <!-- <input type="text" class="form-control disabled" id=Location placeholder=""
              formControlName="Location"> -->
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="License">{{'License' | translate }}</label>
              <select class="custom-select form-control custom-select-2" formControlName="licenseID" [attr.disabled]="(f.terminalID.value)? true : null" [class.disabled]="SelectedID !== 0">
                <option value=0 selected="option.licenseID == licenseID">
                  {{'Select License' | translate }}
                </option>
                <option [ngValue]="option.licenseID" [disabled]=option.maped
                        selected="option.licenseID == licenseID" *ngFor="let option of Licenses">
                  {{option.licenseNo}}
                </option>
              </select>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <label for="code">{{'Device Code' | translate }}</label>
              <input type="text" class="form-control disabled input-bg-dark" id=code placeholder="{{'Code will be generated After Addition' | translate }}" formControlName="code"
                     [readonly]="true" [class.disabled]="true" [ngClass]="{'hide': f.code.value}">
              <div *ngIf="f.code.value" class="form-control border code">
                <img src="assets/images/white-copy.svg" class="move-to-end px-3 code-copy" (click)="copy()" role="button">
                <p class="code-copy w-75 move-to-start mb-0" dir="ltr">{{format(f.code.value)}}</p>
              </div>
              <small class="font-12">{{'Use this device code to sign in to your marn app.' | translate }}</small>
            </div>
          </div>
          <div class="row" *ngIf="checkZatcaCompliance && deviceZATCAComplianced">
            <div class="col-lg-12">
              <div class="alert alert-light d-flex flex-row">
                <div class="me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g style="mix-blend-mode:multiply">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#D1FAE5" />
                    </g>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27Z" fill="white" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16 27C22.0751 27 27 22.0751 27 16C27 9.92487 22.0751 5 16 5C9.92487 5 5 9.92487 5 16C5 22.0751 9.92487 27 16 27ZM11.0502 15.1213C10.6597 15.5118 10.6597 16.145 11.0502 16.5355L13.8786 19.364C14.2692 19.7545 14.9023 19.7545 15.2929 19.364L20.9497 13.7071C21.3402 13.3166 21.3402 12.6834 20.9497 12.2929C20.5592 11.9024 19.926 11.9024 19.5355 12.2929L14.5858 17.2426L12.4644 15.1213C12.0739 14.7308 11.4407 14.7308 11.0502 15.1213Z" fill="#059669" />
                  </svg>
                </div>
                <div>
                  <label class="bold text-black mb-0">{{'Device is verified with ZATCA' | translate }}</label>
                  <p class="text-black font-12 mb-0">
                    {{'All device\'s transactions will be submitted to ZATCA portal' | translate }}
                    <a class="font-12 mb-0" target="_blank" href="https://zatca.gov.sa/ar/E-Invoicing/Introduction/Pages/What-is-e-invoicing.aspx">
                      {{ 'Learn More' | translate }}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="partnerReference">
            <div class="row high-safety-mode">
              <div class="col-3 col-sm-2 col-md-2 col-lg-3 col-xl-3 col-xxl-2">
                <img class="" src="assets/images/marketplace/MarnPay.svg" alt="Marn Pay">
              </div>
              <div class="col">
                <p class="label-bold m-0 font-15">{{'Marn Pay' | translate }}</p>
                <small class="m-0 font-12">{{'Connect a payment terminal to this device' | translate }}</small>
                <br />
                <small class="m-0 font-12">
                  {{'Don\'t have a Payment Terminal ID?' | translate }}
                  <a class="font-blue" href="mailto:sales@marn.com">{{'Contact Marn Sales'| translate }}</a>
                </small>
              </div>
              <div class="form-group col-lg-12 col-sm-12 pt-3">
                <label class="label-bold">{{'Payment Terminal ID' | translate }}</label>
                <input type="text" class="form-control" id="partnerReferenceID" placeholder="" formControlName="partnerReferenceID">
              </div>
            </div>
          </div>
          <hr *ngIf="f.isLinked.value==true" />
          <div class="form-group" *ngIf="f.isLinked.value==true">
            <p class="label-bold"> {{'Device Safety' | translate }}</p>
            <div class="row border rounded py-2 align-items-center">
              <div class="col-3 col-sm-2 col-md-2 col-lg-3 col-xl-3 col-xxl-2">
                <img class="" src="assets/images/marketplace/device.svg" alt="Device">
              </div>
              <div class="col">
                <p class="label-bold m-0 font-15">
                  {{(f.deviceModel.value)? f.deviceModel.value: 'Device' | translate }}
                  <label class="m-0">
                    {{(f.vendorIdentifier.value)?f.vendorIdentifier.value:''}}
                  </label>
                </p>
                <a class="font-blue text-decoration-none">{{'Current Linked Device' | translate}}</a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-12 col-sm-12">
              <p *ngIf="f.isLinked.value==true && f.highSafety.value === true">{{'This Device Code is now used on a device, do you want to unlink this device?' | translate }}</p>
              <button type=button [disabled]="loading" class="btn btn-red" *ngIf="f.isLinked.value==true && f.highSafety.value === true" (click)="unlinkDevice();">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{'Unlink Device' | translate }}
              </button>
            </div>
          </div>
          <div class="form-group">
            <div class="row high-safety-mode align-items-center">
              <div class="col-2 col-sm-1 col-md-1 col-lg-2 col-xl-1 col-xxl-1">
                <img class="" src="assets/images/marketplace/highSafety.svg" alt="Device">
              </div>
              <div class="col-6 col-sm-7 col-md-7 col-lg-6 col-xl-7 col-xxl-7">
                <label for="highSafety" class="label-bold">
                  {{'High Safety Mode' | translate }}
                </label>
              </div>
              <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 text-right">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="highSafety" formControlName="highSafety">
                  <label class="form-check-label" for="highSafety"></label>
                </div>
              </div>
              <div class="col-2 col-sm-1 col-md-1 col-lg-2 col-xl-1 col-xxl-1"></div>
              <div class="col-8 col-sm-7 col-md-8 col-lg-8 col-xl-9 col-xxl-8">
                <p class="font-12">{{'Enabling this will prevent other devices to authenticate using your Device code, by requiring Device Unlink to connect a new device.' | translate }}</p>
              </div>
            </div>
          </div>

          <hr />
          <div class="row">
            <div class="form-group  col-lg-12">
              <div class="status-activebar">
                <label for="Status">

                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.875 18.125H13.125" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M10 15.625V11.25" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5 8.75L10 11.25L12.5 8.75" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.14873 13.0478C5.40556 12.4674 4.80363 11.726 4.38818 10.8795C3.97273 10.033 3.75457 9.10328 3.7501 8.16031C3.73138 4.77263 6.4625 1.95653 9.8493 1.87681C11.1617 1.84521 12.4507 2.22765 13.5335 2.96987C14.6164 3.71209 15.438 4.77641 15.8819 6.01187C16.3257 7.24732 16.3694 8.59118 16.0065 9.85281C15.6437 11.1144 14.8928 12.2298 13.8604 13.0407C13.633 13.2168 13.4486 13.4424 13.3212 13.7003C13.1938 13.9582 13.1267 14.2417 13.125 14.5294L13.125 15C13.125 15.1658 13.0592 15.3248 12.9419 15.442C12.8247 15.5592 12.6658 15.625 12.5 15.625H7.49999C7.33423 15.625 7.17526 15.5592 7.05805 15.442C6.94084 15.3248 6.87499 15.1658 6.87499 15L6.87498 14.5289C6.87427 14.2431 6.80851 13.9612 6.68268 13.7046C6.55685 13.448 6.37425 13.2234 6.14873 13.0478V13.0478Z" stroke="#8628D6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {{'Device Status.' | translate }}
                </label>

                <div class="form-check form-switch">
                  <span class="inactive" (click)="f.statusID.setValue(false)" [className]="f.statusID.value==false ? 'active' : 'inactive'" role="button">{{ 'Inactive' | translate}}</span>
                  <span class="inactive active" (click)="f.statusID.setValue(true)" [className]="f.statusID.value==true ? 'active' : 'inactive'" role="button">{{'Active' | translate}}</span>
                </div>

              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</form>
